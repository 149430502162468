<template>
  <v-dialog max-width="400" data-app v-model="opened">
    <v-card ref="card" v-if="item">
      <v-icon class="btn-close-modal" @click="opened=false" size="36">mdi-close</v-icon>
      <v-card-title>{{item.minus?'Выдача':'Приход'}}</v-card-title>
      <v-card-text>
        <v-row class="row-d-block">
          <v-subheader>Дата</v-subheader>
          <Date v-model="item.date"/>
        </v-row>
        <v-row v-if="item.minus" class="row-d-block">
          <v-subheader>Клинер</v-subheader>
          <v-autocomplete :loading="cleanersLoading" v-model="item.cleaner" :items="cleaners"
                          :search-input.sync="searchCleaner"
                          item-value="n_id"
                          item-text="name"
                          outlined
                          :no-filter="true"
          />
        </v-row>
        <v-row v-for="type in $store.state.user.stockTypes">
          <v-col style="display: flex;
    justify-content: flex-end;
    align-items: center;">
            {{ type.title }}
          </v-col>
          <v-col>
            <v-text-field v-model="item.amounts[type.id]" outlined>
              <template v-slot:append>{{ type[item.minus ? 'units' : 'unitsBig'] }}</template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row class="row-d-block">
          <v-subheader>Комментарий</v-subheader>
          <v-textarea v-model="item.comment" outlined/>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="save" :loading="loading">Сохранить</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import Date from "../../../components/Date";
import cleaners from "../../orders/modals/Cleaners";

export default {
  name: "StockEdit",
  components: {Date},
  data() {
    return {
      opened: false,
      cleanersLoading: false,
      menu: false,
      cleaners: [],
      searchCleaner: '',
      today: this.$moment().format('YYYY-MM-DD'),
      item: null,
      loading: false,
    }
  },
  methods: {
    open(item) {
      this.item = {amounts: {}, date: this.today};
      this.opened = true;
      if (item) this.item = {...this.item, ...item};
      this.$nextTick(() => this.$refs.card.$el.parentNode.scrollTop = 0);
    },
    close() {
      this.opened = false;
    },
    save() {
      this.loading = true;
      this.$store.state.server.request(this.item.id ? 'stock/update/' + this.item.id : 'stock/create', this.item, (data) => {
        this.loading = false;
        this.$root.notify('Строка сохранена', 'success')
        this.$eventBus.$emit('StockSaved')
        this.close();
      }, (data) => {
        this.$root.notify(data && data.error ? data.error : 'Ошибка сохранения', 'error');
        this.loading = false;
      });
    },
  },
  watch: {
    searchCleaner(q) {
      this.$store.state.server.request('stock/cleaners', {q}, data => this.cleaners = data.response);
    }
  }
}
</script>
