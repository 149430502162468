<template>
  <v-app>
    <v-container>
      <v-card>
        <v-card-title class="pb-0">
          Склад
          <v-btn class="ml-2" @click="$refs.StockEdit.open({id:0, minus:1})">+ Выдача</v-btn>
          <v-btn class="ml-2" @click="$refs.StockEdit.open({id:0})">+ Приход</v-btn>
        </v-card-title>
        <v-card-actions>
          <v-row>
            <v-col class="col-auto col-md-2 pt-0 pt-md-2">
              <label>Период</label>
              <DatePeriod simple :intervals="[]" v-model="filters.date"/>
            </v-col>
          </v-row>
        </v-card-actions>
        <v-card-text class="pt-0">
          <v-simple-table style="width: fit-content;">
            <thead>
            <tr>
              <th>Расходник</th>
              <th style="text-align: right">Остаток</th>
              <th style="text-align: right">Закуплено/Потрачено</th>
            </tr>
            </thead>
            <tbody>
            <tr style="cursor: pointer" v-for="row in $store.state.user.stockTypes"
                v-if="row.title"
                :style="'background:'+(localFilter.type==row.id ? '#aef2f6':'')"
                @click="setLocalFilter(row.type)"
            >
              <td>{{ row.title }}</td>
              <td style="text-align: right">
                {{ $root.round(row.amount / row.unitsFactor) }} {{ row.unitsBig }}
              </td>
              <td> {{ $root.round(row.plus / row.unitsFactor) }} {{ row.unitsBig }} /
                {{ $root.round(row.minus / row.unitsFactor) }} {{ row.unitsBig }}
              </td>
            </tr>
            </tbody>
          </v-simple-table>
        </v-card-text>
        <v-card-text class="pt-0">
          <v-data-table
              hover
              :items="rows.filter((item)=>(!localFilter.type || localFilter.type==item.type))"
              :headers="headers"
              item-key="id"
              class="cursor-pointer items"
              :loading="loading"
              :footer-props="{'items-per-page-options':[30]}"
              no-data-text="Строк нет"
              loading-text="Загрузка..."
              mobile-breakpoint="0">
            <template v-slot:item="{item}">
              <tr @click="$refs.StockEdit.open(item)">
                <td>{{ $root.dateToRus(item.date) }}</td>
                <td :style="'color: '+(item.minus ? 'red' : 'green')">{{ item.minus ? 'Выдача' : 'Приход' }}</td>
                <td>
                  {{
                    Object.keys(item.amounts).map(type => `${$store.state.user.stockTypes[type].title}: ${item.amounts[type]} ${$store.state.user.stockTypes[type][item.minus?'units':'unitsBig']}`).join(', ')
                  }}
                </td>
                <td>
                  <CleanerLink v-if="+item.cleaner.n_id" :value="item.cleaner"/>
                  {{ item.comment }}
                </td>
                <td>{{ $root.managerName(item.user) }}</td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>
    <StockEdit ref="StockEdit"/>
  </v-app>
</template>
<script>
import DatePeriod from "../../../components/DatePeriod";
import StockEdit from "./Edit";
import CleanerLink from "../../../components/CleanerLink";

export default {
  name: "Table",
  components: {
    CleanerLink,
    StockEdit,
    DatePeriod,
  },
  data() {
    return {
      rows: [],
      info: {},
      loading: true,
      localFilter: {
        type: 0,
      },
      filters: {
        date: [this.$moment().startOf('month').format('YYYY-MM-DD'), this.$moment().format('YYYY-MM-DD')],
        byItemDate: '0',
        income: '',
      },
      headers: [
        {text: 'Дата', value: 'date'},
        {text: 'Тип', value: 'minus'},
        {text: 'Расходники', value: 'amouns'},
        {text: 'Клинер/Коммент', value: 'comment'},
        {text: 'Менеджер', value: 'user'},
      ],
    }
  },
  methods: {
    setLocalFilter(type) {
      document.querySelector('.c-body').scrollTop = this.$el.querySelector('.v-data-table.items').offsetTop
      if (this.localFilter.type === type) {
        type = 0;
      }
      this.localFilter.type = type;
    },
    reloadTable(clearTable = true) {
      if (clearTable === true) {
        this.loading = true;
        this.setRows([]);
        this.$root.saveData('stock', 'filters', this.filters);
      }
      this.$store.state.server.request('stock/get', this.filters, (data) => {
        this.$store.state.user.stockTypes = data.types;
        this.rows = data.response;
        this.loading = false;
        if (!clearTable) setTimeout(() => {
          if (this.$router.currentRoute.path === '/other/stock') this.reloadTable(false)
        }, 5000)
      }, () => {
        if (!clearTable) setTimeout(() => {
          if (this.$router.currentRoute.path === '/other/stock') this.reloadTable(false)
        }, 5000)
      });
    },
    loadFilters() {
      let saved = this.$root.getData('stock', 'filters') || {};
      for (let field in this.filters) {
        if (saved.hasOwnProperty(field)) this.filters[field] = saved[field];
      }
    },
  },
  mounted() {
    this.loadFilters();
    this.reloadTable(false)
    this.$eventBus.$on('balanceSaved', () => {
      this.reloadTable()
    });
  },
  destroyed() {
    this.$eventBus.$off('balanceSaved');
  }
}
</script>
